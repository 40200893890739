import React from 'react';

const CourseFeatures = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 20px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#F8F9FA",
  };

  const titleStyle = {
    fontSize: "32px",
    fontWeight: 600,
    marginBottom: "30px",
    color: "#011627",
  };

  const topCardContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    width: "95%",
    maxWidth: "100%",
    marginBottom: "20px",
  };

  const bottomCardContainerStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    width: "90%",
    maxWidth: "100%", // Limit width for better centering of two cards
  };

  const cardStyle = {
    backgroundColor: "#9CBFCD",
    width:"30vw",
    height:"30vh",
    padding:"1.4rem",
  

    borderRadius: "25px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    color: "#333",
  };

  const cardTitleStyle = {
    fontSize: "24px",
    fontWeight: 700,
    textAlign:"left",
    marginBottom: "20px",
    color: "#1A3A4F",
  };

  const cardTextStyle = {
    fontSize: "16px",
    textAlign:"left",
    lineHeight: "1.6",
    color: "#555",
  };

  const features = [
    {
      title: "Comprehensive Curriculum",
      description:
        "Our meticulously crafted 30-hour course covers every facet of Business Responsibility & Sustainability Reporting. From fundamental understanding to mastering advanced techniques, embark on a journey that leaves no stone unturned.",
    },
    {
      title: "Suitable for All Levels",
      description:
        "Catering to individuals at every career stage, our course provides a progressive learning experience. Whether starting fresh or seeking skill enhancement, our structured content ensures a rewarding educational journey for all.",
    },
    {
      title: "Expert Instructors",
      description:
        "Learn from industry experts and thought leaders who offer practical insights derived from real-world experience. Benefit from their guidance as they bridge the gap between theory and application.",
    },
    {
      title: "Interactive Learning",
      description:
        "Engage in dynamic discussions, case studies, and hands-on exercises that simulate real-world scenarios. Our interactive approach cultivates not just theoretical understanding but also practical skills essential for success.",
    },
    {
      title: "Stay Updated with Industry Trends",
      description:
        "Sustainability reporting is dynamic, and our course evolves alongside industry trends, standards, and best practices. Stay ahead of the curve and establish yourself as a leader in responsible business practices.",
    },
  ];

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>Why Choose Our BRSR Professional Certification Course</div>
      
      {/* Top three cards */}
      <div style={topCardContainerStyle}>
        {features.slice(0, 3).map((feature, index) => (
          <div key={index} style={cardStyle}>
            <div style={cardTitleStyle}>{feature.title}</div>
            <div style={cardTextStyle}>{feature.description}</div>
          </div>
        ))}
      </div>
      
      {/* Bottom two cards */}
      <div style={bottomCardContainerStyle}>
        {features.slice(3, 5).map((feature, index) => (
          <div key={index} style={cardStyle}>
            <div style={cardTitleStyle}>{feature.title}</div>
            <div style={cardTextStyle}>{feature.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseFeatures;
