import React from "react";
import trusted from "./trusted.png";
import ram from "./ramaiah.png";
import vr from "./vr.png";

import manipal from "./manipal.png";

import CIIE from "./CIIE.png";

const Partners = () => {
  return (
    <section style={{ height: "50%" }}>
      <div
        style={{
          backgroundImage: `url(${trusted})`, // Correct syntax
          backgroundSize: "cover", // Cover to fill the area
          backgroundPosition: "center", // Center the image
          height: "50%", // Adjust the height as needed
          width: "100%",
          padding: "5%",
        }}
      >
        <div
          style={{
            fontSize: "24px",
            color: "#011627",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "20px" }}>T R U S T E D</span>
          <span>P A R T N E R S</span>
        </div>
        <div
          style={{
            fontSize: "64px",
            color: "#011627",
            fontWeight: 600,
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          Forging Sustainable
        </div>
        <div
          style={{
            fontSize: "64px",
            color: "#011627",
            fontWeight: 600,
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          Solutions, Hand in Hand.
        </div>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ width: "25%", padding: "5%" }}>
            <img src={manipal} />
          </div>
          <div style={{ width: "25%", padding: "5%" }}>
            <img src={vr} />
          </div>
          <div style={{ width: "25%", padding: "5%" }}>
            <img src={ram} />
          </div>
          <div style={{ width: "25%", padding: "5%" }}>
            <img src={CIIE} />
          </div>
        </div>
      </div>
      <div
        style={{
       
     
          height: "50%", // Adjust the height as needed
          width: "100%",
          padding: "5%",
          paddingBottom:"0%"
        }}
      >
        <div
          style={{
            fontSize: "24px",
            color: "#011627",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "20px" }}>B A C K E D </span>
          <span>B Y</span>
        </div>
        <div
          style={{
            fontSize: "64px",
            color: "#011627",
            fontWeight: 600,
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          Our Investors
        </div>
       
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ width: "25%", padding: "5%" }}>
            <img src={manipal} />
          </div>
          
          <div style={{ width: "25%", padding: "5%" }}>
            <img src={ram} />
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Partners;
