import React, { useState } from "react";
import BlogDetail from "./BlogDetail"; // Component for displaying individual blog details
import ParticleBackground from "../../../Home/Components/Resources/ParticleBackground";
import "./Section.css"; // Styling for the main blog section and list view
import place from './place.png'

// Sample data for demonstration
const data = Array.from({ length: 54 }, (_, index) => ({
  id: index + 1,
  title: "Navigating the ESG Seas",
  category: "ESG in Supply Management",
  imageUrl: place,
  author: "Smith Read",
  subtitle: "Conceptualizing ESG Integration in Supply Chain for long-term business sustainability",
  sections: [
    {
      heading: "Introduction",
      text: "In the ever-evolving landscape of corporate sustainability, the three pillars of ESG...",
    },
    {
      heading: "Understanding the Three Pillars of ESG",
      text: "Environmental, Social, and Governance factors play a critical role...",
    },
    {
      heading: "The Regulatory Landscape",
      text: "Amidst shifting tides, new frameworks and directives...",
    },
    // Additional sections as needed
  ],
}));

const Section = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Calculate the range of items to show on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate page numbers for pagination display
  const startPage = Math.floor((currentPage - 1) / 5) * 5 + 1;
  const endPage = Math.min(startPage + 4, totalPages);
  const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 5 > totalPages ? totalPages : currentPage + 5);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 5 < 1 ? 1 : currentPage - 5);
    }
  };

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
  };

  const handleBack = () => {
    setSelectedBlog(null);
  };

  return (
    <div className="section-containerr">
      <ParticleBackground />
      
      {/* Blog list view */}
      {selectedBlog ? (
        <BlogDetail blog={selectedBlog} onBack={handleBack} />
      ) : (
        <>
          <div style={{ fontSize: "24px", letterSpacing: "5px", fontWeight: 400, marginBottom: "0.1%" }}>
            BLOG
          </div>
          <div style={{ fontSize: "84px", fontWeight: 700 }}>READS</div>
          <div style={{ fontSize: "24px", fontWeight: 600, marginBottom: "3%" }}>
            ARTICLES & BLOGS FROM RIU
          </div>
          <div className="grid-containerr" style={{ zIndex: 3 }}>
            {currentItems.map((item) => (
              <div key={item.id} className="cardd" style={{cursor:"pointer"}} onClick={() => handleBlogClick(item)}>
                <div className="image-containerr">
                  <img src={item.imageUrl} alt={item.title} style={{ height: "250px" }} className="image" />
                  <div className="hover-overlay">
                    <span>View Post</span>
                  </div>
                </div>
                <div className="card-content">
                  <div className="titlee">{item.title}</div>
                  <div className="descriptionn">{item.subtitle}</div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          <div className="pagination">
            <button
              className={`page-button arrow-button ${currentPage === 1 ? "disabled-button" : ""}`}
              onClick={handlePrevious}
              disabled={currentPage === 1}
            >
              &lt;
            </button>

            {pages.map((page) => (
              <button
                key={page}
                className={`page-button ${page === currentPage ? "active-page-button" : ""}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ))}

            {endPage < totalPages && <span className="dots">...</span>}

            <button
              className={`page-button arrow-button ${currentPage === totalPages ? "disabled-button" : ""}`}
              onClick={handleNext}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Section;
