import React from 'react'
import SectionFour from '../../../../../Academy/Components/SectionFour/SectionFour'

const SectionFourr = () => {
  return (
    <div style={{marginTop:"20px"}}>
        <SectionFour/>
    </div>
  )
}

export default SectionFourr