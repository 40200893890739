import React from "react";
import "./footer.css";
import riu from "./riu.png";
import {
  FaLinkedin,
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer" style={{ height: "100%",width:"100%" }}>
      <div className="footer-content" style={{ paddingLeft: "2%" }}>
        {/* Left Section */}
        <div className="footer-left" style={{width:"35%"}}>
          <img src={riu} alt="RIU Logo" className="footer-logo" />{" "}
          {/* Replace with your logo */}
          <p className="footer-tagline">
            Elevating Sustainability, One Step at a Time.
          </p>
          <p style={{ fontSize: "20px" }}>
            Curious for more details? Let's connect and{" "}
          </p>
          <p style={{ fontSize: "20px" }}>explore further.</p>
          <div className="email-input-container">
            <input
              type="email"
              placeholder="Enter Mail Id"
              className="email-input"
            />
            <button className="send-button">SEND</button>
          </div>
          <h4>SOCIALS</h4>
          <div className="social-icons">
            <FaLinkedin className="social-icon" />
          </div>
        </div>

        <div style={{width:"65%", display:"flex", justifyContent:"space-between", height:"100%"}}>
          <div className="footer-middle" style={{height:"45%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
            <h4>SUSTAINABLE FRAMEWORK</h4>
            <h4>SOLUTIONS</h4>
            <h4>ACADEMY</h4>
          </div>
          <div className="footer-middle" style={{height:"35%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
              <h4>COMPANY</h4>
              <p>About</p>
              <p>Blog</p>
              <p>Annual Filings</p>
          </div>

          {/* Right Section */}
          <div className="footer-right" style={{height:"50%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
            <h4>CONTACT</h4>
            <p>
              <FaPhoneAlt /> (+91) 973 - 875 - 7221
            </p>
            <p>
              <FaEnvelope /> contact@riu.ai
            </p>
            <p>
              <FaMapMarkerAlt /> No. 152, 2nd Cross, MICO Layout, 2 Stage,
              Arekere, Bengaluru, Karnataka, India - 560076
            </p>
          </div>
        </div>


        {/* Middle Section */}
      </div>
      <div className="policies">
          <p>Privacy Policy</p>
          <p>Terms & Conditions</p>
          <p>Customer Support Policy</p>
          <p>Cancellation & Refund Policy</p>
        </div>

      <div className="footer-bottom" style={{display:"flex", width:"100%", alignItems:'center', justifyContent:"center"}}>
       
        <p>© Copyright 2023 – All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
