import React from 'react';

const ComponentThree = () => {
  const containerStyle = {
    backgroundColor: "#F8F9FA",
    padding: "40px 20px",
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems:"center",
  };

  const cardsWrapperStyle = {
    display: "flex",
    marginTop:"5vh",
    justifyContent:"space-between",
   
    gap: "20px",
    width: "80%",
  
  };

  const cardStyle = {
    border: "3px solid #9AC1D0", // Blue border color
    backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent white background
    width:"23%",
    padding: "30px",
    borderRadius: "20px",
    textAlign: "left",
  };

  const titleStyle = {
    fontSize: "32px",
    display:"flex",
    flexWrap:"wrap",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
  };

  const paragraphStyle = {
    fontSize: "16px",
    display:"flex",
    flexWrap:"wrap",
    color: "#555",
  };

  return (
    <div style={containerStyle}>
        <div style={{fontSize:"32px", fontWeight:700,}}>
        Course Highlights
        </div>
      <div style={cardsWrapperStyle}>
        <div style={cardStyle}>
          <h3 style={titleStyle}>Introduction to BRSR</h3>
          <p style={paragraphStyle}>
          Gain a comprehensive understanding of the principles and frameworks that govern Business Responsibility & Sustainability Reporting.
          </p>
        </div>
        <div style={cardStyle}>
          <h3 style={titleStyle}>Ethical Considerations</h3>
          <p style={paragraphStyle}>
          Explore the ethical dimensions of business responsibility and sustainability, ensuring alignment with the highest standards of integrity.
          </p>
        </div>
        <div style={cardStyle}>
          <h3 style={titleStyle}>Stakeholder Engagement</h3>
          <p style={paragraphStyle}>
          Understand the importance of engaging with stakeholders and learn strategies to build meaningful relationships that contribute to your organization's success.
          </p>
        </div>
        <div style={cardStyle}>
          <h3 style={titleStyle}>Data Collection and Analysis</h3>
          <p style={paragraphStyle}>
          Master the art of collecting, analyzing, and interpreting data to create meaningful and impactful sustainability reports.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ComponentThree;
