import React from 'react'
import ScrollSpyComponent from './Components/ScrollSpyComponent'
import './home.css'

const HomePage = () => {
  return (
    <div style={{height:"86vh"}}>
      <ScrollSpyComponent/>
    </div>
  )
}

export default HomePage