import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import MainBookDemo from './BookDemo/MainBookDemo';import './TabbedNavigation.css'
import logo from "../Images/logo.png";
import { useNavigate } from 'react-router-dom'; // useNavigate from React Router v6
import HomePage from './Home/HomePage';
import About from './About/about';
import Academy from './Academy/academy';
import Contact from './Contact/contact';
import Blogs from './Blogs/Blogs';
import DemoBook from './DemoBook/DemoBook';
import Solutions from './Solutions/Solutions';
import EsgDataManagement from './Solutions/SubComponents/ESGDataManagement/EsgDataManagement';
import CarbonAccounting from './Solutions/SubComponents/CarbonAccountingPlatform/CarbonAccountingPlatform';
import SusAndESG from './Solutions/SubComponents/SustainibiltyAndEsgReporting/SusAndEsg';
import EsgAnalytics from './Solutions/SubComponents/ESG Analytics/EsgAnalytics';
import SupplierAssesment from './Solutions/SubComponents/SupplierAssesment/SupplierAssesment';
function TabbedNavigation() {
  const navigate = useNavigate(); // Initialize useNavigate

  // Define the navigateHome function
  const navigateHome = () => {
    navigate('/'); // This will navigate to localhost:3000/
  };
  const styleMain = {
    display: "flex",
    flexDirection: "column",
  }

  const navStyle = {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#ffffff',
    height:"14vh",
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
    marginBottom:"0px"
   

  }

  return (
    <div style={styleMain}>
      <div style={navStyle}>
        <div>
          <img src={logo} alt='Home' onClick={navigateHome} />
        </div>


        <nav className="navbar" style={{justifyContent:"flex-start"}}>
          <ul className="nav-links">
            <li className="nav-item"><Link to="/solutions">Solutions</Link></li>
            <li className="nav-item"><Link to="/academy">Academy</Link></li>
            <li className="nav-item"><Link to="/company">Company</Link></li>
            <li className="nav-item"><Link to="/contact">Contact</Link></li>
          </ul>

          <a className="book-demo" href="/book-demo">Book Demo</a> {/* Book Demo Button */}
        </nav>

      </div>


      {/* Define Routes */}
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path="/services" element={< ></>} />
        <Route path="/solutions" element={<Solutions/>} />
        <Route path="/academy" element={<Academy/>} />
        <Route path="/company" element={< ></>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/blogs" element={<Blogs/>} />
        <Route path="/book-demo" element={<DemoBook />} />
        <Route path="/solutions/esg-data-management" element={<EsgDataManagement />} />
        <Route path="/solutions/carbon-accounting" element={<CarbonAccounting />} />
        <Route path="/solutions/sustainabilityandesg" element={<SusAndESG />} />
        <Route path="/solutions/esganalytics" element={<EsgAnalytics />} />
        <Route path="/solutions/supplierassesment" element={<SupplierAssesment />} />





      </Routes>
    </div>
  )
}

export default TabbedNavigation;
