import React from 'react'
import ScrollSpyComponent from './Components/ScrollSpyComponentBlogs'

const Blogs = () => {
  return (
    <div style={{height:"86vh", width:"100%"}}>
      <ScrollSpyComponent/>
    </div>
  )
}

export default Blogs