import React from "react";
import phone from "./phone.png"
import mail from "./mail.png"

import location from "./location.png"
import map from "./map.png"
import link from "./link.png"


function SectionOne() {
  const styles = {
    mainDiv: {
        height: "160vh",
  textAlign: "left",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  paddingLeft: "4rem",
  paddingRight: "4rem",
  borderBottomLeftRadius: "25px", // Rounded bottom-left corner
  borderBottomRightRadius: "25px", // Rounded bottom-right corner
    },

    contactContainer: {
      display: "flex",
      padding: "20px",
      width:"100%",
      height:"83%",
      paddingLeft: "0rem",
      paddingRight: "0rem",
      gap: "60px",
    },
    formContainer: {
      flex: 0.85,
      border: "3px solid #B2CFDB",
      padding: "50px",
      borderRadius: "15px",
    },
    input: {
      width: "100%",
      marginBottom: "35px",
      padding: "10px",
      paddingTop:"25px",
      paddingBottom:"25px",

      border: "2px solid black",
      borderRadius: "5px",
    },
    button: {
      width: "100%",
      padding: "10px",
      paddingTop:"16px",
      paddingBottom:"16px",
      backgroundColor: "#3F88A5",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    contactDetails: {
      flex: 1.15,
      backgroundColor: "#B2CFDB",
      padding: "50px",
      paddingTop:"40px",
      borderRadius: "8px",
    },
    contactItem: {
      display: "flex",
      alignItems: "center",
      background:"#E4E9ED",
      padding:"25px",
      borderRadius:"5px",
      marginBottom: "20px",
    },
    icon: {
      fontSize: "24px",
      marginRight: "10px",
    },
    map: {
      width: "100%",
      height:"30vh",
      borderRadius: "8px",
    },
    social: {
      display: "flex",
      flexDirection:"column",
      marginTop:"5px",
      alignItems: "flex-start",
      gap: "1px",
    },
  };

  return (
    <div style={styles.mainDiv}>
        <div style={{fontSize:"24px", letterSpacing:"17%", height:"2%"}}>
            CONTACT
        </div>
        <div style={{fontSize:"64px", fontWeight:700, height:"10%"}}>
        Embark on a Sustainable Journey with Us
        </div>
        <div style={{fontSize:"24px", letterSpacing:"17%", height:"5%"}}>
        Connect, Collaborate, and Together, Let's Build a Better Future. Reach out to RIU Today
        </div>
      <div style={styles.contactContainer}>
        <div style={styles.formContainer}>
          <form>
            <input
              type="text"
              placeholder="First Name"
              style={styles.input}
              required
            />
            <input
              type="text"
              placeholder="Last Name"
              style={styles.input}
              required
            />
            <input
              type="email"
              placeholder="Email Id"
              style={styles.input}
              required
            />
            <input
              type="tel"
              placeholder="Phone Number"
              style={styles.input}
              required
            />
            <input type="text" placeholder="Company" style={styles.input} />
            <textarea
              placeholder="Other Queries"
              style={styles.input}
            ></textarea>
            <button type="submit" style={styles.button}>
              SUBMIT
            </button>
            <p style={{marginTop:"10px",fontSize:"22px"}}>
              By submitting this form, you agree to our{" "}
              <strong>Terms and Conditions</strong>. Please review our{" "}
              <strong>Privacy Policy</strong> for information on how we handle
              your data.
            </p>
          </form>
        </div>
        <div style={styles.contactDetails}>
          <h2 style={{fontSize:"40px", fontWeight:600, marginBottom:"30px"}}>Contact Details</h2>
          <div style={styles.contactItem}>
            <span style={styles.icon}>
                <img src={phone}/>
            </span>
            <div>
              <div style={{fontSize:"24px", fontWeight:700}}>Phone Number</div>
              <p style={{fontSize:"22px", fontWeight:400}}>(+91) 973 – 875 – 7221</p>
            </div>
          </div>
          <div style={styles.contactItem}>
            <span style={styles.icon}>
            <img src={mail}/>

            </span>
            <div>
              <div style={{fontSize:"24px", fontWeight:700}}>Mail ID</div>
              <p style={{fontSize:"22px", fontWeight:400}}>contact@riu.ai</p>
            </div>
          </div>
          <div style={styles.contactItem}>
            <span style={styles.icon}>
            <img src={location}/>

            </span>
            <div>
              <div style={{fontSize:"24px", fontWeight:700}}>Address</div>
              <p style={{fontSize:"22px", fontWeight:400}}>
                No. 152, 2nd Cross, MICO Layout, 2 Stage, Arekere, Bengaluru,
                Karnataka, India - 560076
              </p>
            </div>
          </div>
          <div className="map">
            <img src={map} alt="map" style={styles.map} />
          </div>
          <div style={styles.social}>
            <p style={{marginBottom:"0px",fontSize:"24px",letterSpacing:"17%"}}>FOLLOW US ON</p>
            <div style={styles.icon}>
                <img src={link} style={{height:"4vh"}}/>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionOne;
