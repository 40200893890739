import React from 'react'
import ScrollSpyComponent from './Components/ScrollSpyComponentContact'

const Contact = () => {
  return (
    <div style={{height:"86vh"}}>
      <ScrollSpyComponent/>
    </div>
  )
}

export default Contact