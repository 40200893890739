import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Section from "./SectionOne/Section.jsx";
import Scrollspy from "react-scrollspy";
import SectionTwo from "./SectionTwo/SectionTwo";
import SectionThree from "./SectionThree/SectionThre..jsx";
import SectionFour from "./SectionFour/SectionFour.jsx";

const ScrollSpyComponent = () => {
  const [activeSection, setActiveSection] = useState("section1");

  useEffect(() => {
    const sections = document.querySelectorAll(".section");

    const handleScroll = () => {
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      console.log(section);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="d-flex w-100">
      {/* <nav id="scrollspy-nav" className="nav flex-column scrollspy-nav">
        <a
          className="nav-link"
          onClick={() => handleScrollToSection("section1")}
        >
          <div
            className={`dot ${activeSection === "section1" ? "active" : ""}`}
          ></div>
        </a>
        <a
          className="nav-link"
          onClick={() => handleScrollToSection("section2")}
        >
          <div
            className={`dot ${activeSection === "section2" ? "active" : ""}`}
          ></div>
        </a>
        <a
          className="nav-link"
          onClick={() => handleScrollToSection("section3")}
        >
          <div
            className={`dot ${activeSection === "section3" ? "active" : ""}`}
          ></div>
        </a>
        <a
          className="nav-link"
          onClick={() => handleScrollToSection("section4")}
        >
          <div
            className={`dot ${activeSection === "section4" ? "active" : ""}`}
          ></div>
        </a>
      </nav> */}
      {/* <nav>
        <Scrollspy items={['section1', 'section2', 'section3', 'section4']} currentClassName="active">
          <li><a href="#section1">Section 1</a></li>
          <li><a href="#section2">Section 2</a></li>
          <li><a href="#section3">Section 3</a></li>
          <li><a href="#section4">Section 4</a></li>
        </Scrollspy>
      </nav> */}
      <nav className="scrollspy-nav">
        <Scrollspy
          items={["section1", "section2", "section3", "section4"]}
          currentClassName="active"
        >
          <li>
            <div className="outer-circle">
              <a href="#section1" className="inner-circle"></a>
            </div>
          </li>
          <li>
            <div className="outer-circle">
              <a href="#section2" className="inner-circle"></a>
            </div>
          </li>
         
        </Scrollspy>
      </nav>

      {/* Page Sections */}
      {/* <div className="sections">
        <div id="section1" className="section">
          <SectionOne />
        </div>
        <div id="section2" className="section">
          <SectionTwo />
        </div>
        <div id="section3" className="section" style={{ height: "300vh" }}>
          <SectionThree />
        </div>
        <div
          id="section4"
          className="section"
          style={{ height: "440vh", alignItems: "flex-start" }}
        >
          <SectionFour />
        </div>
      </div> */}

      <div style={{ paddingTop: "10px" , width:"100%"}}>
        <div id="section1" className="w-100">
          <Section />
        </div>
        <div id="section2">
          <SectionTwo />
        </div>
       
      </div>
    </div>
  );
};

export default ScrollSpyComponent;
