import React, { useRef, useEffect, useState } from "react";
import ParticleBackground from "../../../../../Home/Components/Resources/ParticleBackground";

// Particle system with instancing and input interaction

const SectionOne = () => {
  const [texture, setTexture] = useState();
  const [mouse, setMouse] = useState({ x: 0, y: 0 });



  const handleMouseMove = (event) => {
    const { clientX, clientY, innerWidth, innerHeight } = event;
    setMouse({
      x: (clientX / innerWidth) * 2 - 1,
      y: -(clientY / innerHeight) * 2 + 1,
    });
  };

  return (
    <div
      className="section-one"
      onMouseMove={handleMouseMove}
      style={{
        position: "relative",
        height: "50vh",
        paddingTop: "0px",
        marginTop: "0px",
        width: "100%",
        display: "flex",
        alignItems: "end",
        justifyContent: "end",
        textAlign: "center",
        color: "#1a1a1a",
    background:"white"
      }}
    >
      <ParticleBackground />

      <div
        className="content"
        style={{
          position: "relative",
          width: "100%",
          display:"flex",flexDirection:"column",
          alignItems:"center", justifyContent:'center',
    
          marginTop: "-5%",
          zIndex: 10,
          color: "#1a1a1a",
        }}
      >
        <h2
          style={{
            fontFamily: "'Open Sans', sans-serif", // Specify Open Sans font
            fontSize: "24px", // Set font size to 24px
            color: "#6c757d",
            border:"1px solid #3f88a5",
            width:"30%",
            padding:"10px 0px",
            margin: 0,
            letterSpacing: "0.1em",
          }}
        >
          ESG DATA MANAGEMENT
        </h2>

        <h1
          style={{
            fontFamily: "'Open Sans', sans-serif", // Specify Open Sans font
            fontSize: "64px", // Set font size to 24px
            color: "#011627",
            fontWeight: "bold",
            marginTop:"20px"
          }}
        >
          ESG Data Management Platform
        </h1>
      
       
        <div style={{fontSize:"24px", fontWeight:700,color:"#011627", marginBottom:"3%"}}>
        Easily collect data and centralize your emissions data
        </div>

   

       

        <div
          className="scroll-indicator"
          style={{
            width: "30px",
            height: "50px",
            border: "2px solid #1a1a1a",
            borderRadius: "20px",
            margin: "20px auto 0",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              backgroundColor: "#1a1a1a",
              animation: "scroll 1.5s infinite",
            }}
          />
        </div>
      </div>

      <style>
        {`
      @keyframes scroll {
        0% { opacity: 1; top: 8px; }
        50% { opacity: 0; top: 20px; }
        100% { opacity: 1; top: 8px; }
      }
    `}
      </style>
    </div>
  );
};

export default SectionOne;
