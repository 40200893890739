// ParticleBackground.js
import React, { useEffect } from 'react';
import * as particlesJS from 'particles.js';


const ParticleBackground = () => {
    useEffect(() => {
        if (window.particlesJS) {
            window.particlesJS('particles-js', {
                // Your particles.js configuration
            });
        }
    }, []);
    useEffect(() => {
        if (window.particlesJS) {
            window.particlesJS('particles-js', {
                particles: {
                    number: {
                        value: 100,
                        density: {
                            enable: true,
                            value_area: 800
                        }
                    },
                    color: {
                        value: '#d3d3d3'
                    },
                    shape: {
                        type: 'circle',
                        stroke: {
                            width: 0,
                            color: '#000000'
                        }
                    },
                    opacity: {
                        value: 0.8,
                        random: true
                    },
                    size: {
                        value: 5,
                        random: true
                    },
                    line_linked: {
                        enable: false
                    },
                    move: {
                        enable: true,
                        speed: 30,
                        direction: 'none',
                        random: true,
                        straight: false,
                        out_mode: 'out',
                        bounce: false,
                        attract: {
                            enable: false
                        }
                    }
                },
                interactivity: {
                    detect_on: 'canvas',
                    events: {
                        onhover: {
                            enable: false
                        },
                        onclick: {
                            enable: false
                        }
                    },
                    modes: {
                        repulse: {
                            distance: 0
                        },
                        push: {
                            particles_nb: 0
                        }
                    }
                },
                retina_detect: true
            });
        } else {
            console.error("particlesJS is not loaded.");
        }
    }, []);
    

    return <div id="particles-js" style={styles.particlesContainer}></div>;
};

const styles = {
    particlesContainer: {
        position: 'absolute',
        width: '80%',
        height: '77%',
        zIndex: 2, // Make sure it's behind other content
    },
};

export default ParticleBackground;
