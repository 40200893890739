import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import group from "../../../../Components/SectionTwo/group.png";
import business from "./businessgroup.png"

const SectionTwo = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/solutions/book-demo");
  };
  const containerStyle = {
    backgroundColor: "#F8F9FA",
    padding: "40px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const cardsWrapperStyle = {
    display: "flex",
    marginTop: "5vh",
    justifyContent: "space-between",

    gap: "20px",
    width: "80%",
  };

  const cardStyle = {
    border: "3px solid #9AC1D0", // Blue border color
    backgroundColor: "rgba(255, 255, 255, 0.2)", // Transparent white background
    width: "45%",
    padding: "30px",
    borderRadius: "20px",
    textAlign: "left",
  };

  const titleStyle = {
    fontSize: "32px",
    display: "flex",
    flexWrap: "wrap",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
  };

  const paragraphStyle = {
    fontSize: "16px",
    display: "flex",
    flexWrap: "wrap",
    color: "#555",
  };

  return (
    <>
      <Row className="" style={{ width: "100%", padding: "0px 4rem" }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
            ESG Data Management
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
            Track, analyze, and consolidate your ESG data using a centralized
            data collection and management system for better decision-driven
            analytics and value creation.
          </div>
          <Button
            onClick={handleClick}
            style={{
              color: "white",
              background: "#3F88A5",
              fontSize: "16px",
              padding: "15px 30px",
              width: "15vw",
              fontWeight: 600,
              border: "none",
            }}
            className="mt-3"
          >
            BOOK A DEMO
          </Button>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={group} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
      <div style={containerStyle}>
        <div style={{ fontSize: "32px", fontWeight: 700 }}>
          Features of ESG Data Management
        </div>
        <div style={cardsWrapperStyle}>
          <div style={cardStyle}>
            <h3 style={titleStyle}>
              For Streamlined Data Collection and Management
            </h3>
            <p style={paragraphStyle}>
              Effortlessly gather and organize your data with our intuitive
              interface. Whether it's through manual input or seamless
              integration with Excel sheets, CSV files, or APIs, managing your
              data has never been easier
            </p>
          </div>
          <div style={cardStyle}>
            <h3 style={titleStyle}>Easy Configuration of ESG Metrics</h3>
            <p style={paragraphStyle}>
              Simplify the process of configuring and evaluating ESG metrics
              with our integrated database. Stay up-to-date with the latest
              standards and methodologies, including DEFRA Emission Factors and
              IPCC Global Warming Potentials (GWPs), ensuring precise GHG
              accounting every step of the way.
            </p>
          </div>
        </div>
      </div>
      <Row className="" style={{ width: "100%", padding: "0px 4rem",marginBottom:"1rem"  }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
            Insightful Dashboard
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
            View and analyze data using dedicated dashboards for each ESG
            metric. Derive meaningful insights, foresee trends, and get overall
            ESG performance snapshots of your organization
          </div>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={group} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
      <Row className="" style={{ width: "100%", padding: "3rem 4rem", backgroundColor: "#F8F9FA",     }}>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
          Scale seamlessly 
          </h2>
          <h2 className="" style={{ fontSize: "64px", fontWeight: 600 }}>
          as your business grows
          </h2>
          <div style={{ fontSize: "24px", fontWeight: 500 }}>
          RIU allows them to support their  organization in this rapid expansion without additional costs and allowed them to  maintain simplicity in an increasingly complex environment.
          </div>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center"
        >
          <img
            src={business} // replace with actual image path
            alt="ESG Data Visualization"
            className="img-fluid"
            style={{ marginTop: "30px" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SectionTwo;
