import React from 'react'
import lofo from "./logo.png"

const SectionThree = () => {
  return (
    <div style={{width:"100%", display:"flex", flexDirection:"column",alignItems:"center", justifyContent:"center"}}>
        <div style={{width:"40%", fontWeight:"bold", fontSize:"48px", marginTop:"25px", textAlign:"center"}}>
            Trusted Partners
        </div>
        <div style={{marginTop:"7rem"}}>
            <img src={lofo}/>
        </div>

    </div>
  )
}

export default SectionThree