import React from "react";
// import LogoDemo from "../Image/researchers-looking-alternative-energy-souces 1.png";
import "./BookDemo.css";
// import microsoftLogo from "../Image/Microsoft Logo.png";
// import airbnbLogo from "../Image/Airbnb Logo.png";
// import googleLogo from "../Image/Google Logo.png";
// import hubLogo from "../Image/Layer 2.png";

const BookDemo = () => {
  return (
    <div className="Book-dems" style={{ display: "flex",marginBottom:'8em' }}>
      <div className="BookDemo-firstpara row ms-5">
        <div className="col-8">
          <div style={{ fontSize: "72px" }}>
            Explore Our Network of ESG Experts
          </div>
        </div>
        <div style={{ fontSize: "24px", fontWeight: "450" }} className="mt-4">
          Connecting You with Experts in Sustainability,
          <p>Compliance, and Strategy. </p>
        </div>
        <div className="mt-4">
          <button className="bookdemo-btn" style={{ border: "2px solid" }}>
            Join Our Network{" "}
          </button>
          <button className="bookdemo-btn ms-5" style={{ border: "2px solid" }}>
            Connect With Expert
          </button>
        </div>
        <div className="mt-5">
          <div style={{ fontSize: "22px", fontWeight: "bold" }} >
            Trusted by
          </div>
          <div style={{}} className="mt-4">
            {/* <img className="me-4" src={microsoftLogo} alt="microsoftlogo" /> */}
            {/* <img className="me-4" src={airbnbLogo} alt="logo"/> */}
            {/* <img src={googleLogo} className="me-4" alt="logo"/> */}
            {/* <img src={hubLogo} alt="logo" /> */}
          </div>
        </div>
      </div>
      <div className="Book-Demo-logo">
        {/* <img src={LogoDemo} alt="Logo" style={{ height: "75vh" }} /> */}
      </div>
    </div>
  );
};

export default BookDemo;
