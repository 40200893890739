import React from 'react';

function SectionTwo() {
  return (
    <div style={{ height:"95vh", fontFamily: "'Open Sans', sans-serif" }}>
      {/* Our Story Section */}
      <div
        style={{
          backgroundColor: '#D9E7ED',
          padding: '2rem',
          height:"27%",
    
   
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{width:"20%",height:"100%", display:"flex", paddingTop:"0.1rem", alignItems:"flex-start",justifyContent:"flex-start"}}>
        <h2
          style={{
            fontSize: '38px',
            color: '#011627',
            fontWeight: 700,
         
            flexShrink: 0,
          }}
        >
          OUR STORY
        </h2>

        </div>
        <div style={{width:"75%", display:"flex", flexWrap:"wrap", height:"100%", paddingTop:"0.1rem", alignItems:"flex-start",justifyContent:"flex-start"}}>
        <p style={{ color: '#011627', fontSize: '18px', }}>
          At our core, we're a passionate team of technologists and ESG
          professionals with a clear mission: simplifying ESG compliance
          worldwide. Through our cutting-edge AI platform, we automate data
          collection, offer market intelligence on key KPIs, and streamline
          stakeholder training. Our goal is simple yet profound: to make ESG
          compliance accessible and affordable for every player in your value
          chain, driving a brighter, sustainable future for all.
        </p>

        </div>
       
      </div>

      {/* Vision and Mission Section */}
      <div
        style={{
          display: 'flex',
          paddingLeft:"4rem",
         
          paddingRight:"4rem",
          borderBottom:"1px solid black",
          paddingBottom:"4rem",
          padding:"3.5rem",
          height:"40%",
          gap: '1rem',
      
        }}
      >
        <div
          style={{
            backgroundColor: '#D9E7ED',
            padding: '2.2rem',
            borderRadius: '8px',
            flex: 1,
          }}
        >
          <h3
            style={{
              fontSize: '32px',
              color: '#1a1a1a',
              fontWeight: 600,
              marginBottom: '0.5rem',
            }}
          >
            VISION
          </h3>
          <p style={{ color: '#011627', fontSize: '18px', lineHeight: '1.5' }}>
            Our vision is to lead the global charge in value chain ESG
            management, revolutionizing practices to seamlessly integrate
            sustainability across organizations and supply chains worldwide.
          </p>
        </div>

        <div
          style={{
            backgroundColor: '#D9E7ED',
            padding: '2.2rem',
            borderRadius: '8px',
            flex: 1,
          }}
        >
          <h3
            style={{
              fontSize: '32px',
              color: '#1a1a1a',
              fontWeight: 600,
              marginBottom: '0.5rem',
            }}
          >
            MISSION
          </h3>
          <p style={{ color: '#011627', fontSize: '18px', lineHeight: '1.5' }}>
          Our mission is to simplify ESG compliance, making it effortless, affordable, and accessible for every participant in the global value chain.
          </p>
        </div>
      </div>

      {/* Why RIU Section */}
      <div
        style={{
          backgroundColor: 'white',
          padding: '2rem',
          height:"25%",
    
          marginBottom: '2rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{width:"20%",height:"100%", display:"flex", paddingTop:"0.1rem", alignItems:"flex-start",justifyContent:"flex-start"}}>
        <h2
          style={{
            fontSize: '38px',
            color: '#011627',
            fontWeight: 700,
         
            flexShrink: 0,
          }}
        >
          WHY RIU
        </h2>

        </div>
        <div style={{width:"75%", display:"flex", flexWrap:"wrap", height:"100%", paddingTop:"0.1rem", alignItems:"flex-start",justifyContent:"flex-start"}}>
        <p style={{ color: '#011627', fontSize: '18px', }}>
        Sustainability transcends size. Whether you're a local retailer or a global chain, our mission is to democratize ESG performance improvement across all sectors of the supply chain. With our dedicated team of technical and sustainability experts, we've streamlined the arduous task of collecting, compiling, and reporting sustainability data. By condensing months of work into mere hours, we not only reduce costs but also enable timely interventions, ensuring continuous improvement. Moreover, our unwavering commitment to 100% data security and transparency throughout the value chain eliminates the risk of greenwashing, enhancing stakeholder trust and confidence in your sustainability efforts.
        </p>

        </div>
       
      </div>
    </div>
  );
}

export default SectionTwo;
