import React, { useRef, useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { TextureLoader } from "three";
import academy from "./academy.png";
import man from "./manipal.png";
import one from "./blogone.png";
import two from "./blogtwo.png";
import three from "./blogthree.png";
import lets from "./lets.png";
import Footer from "./Footer/Footer";

// Utility function to load image and get pixel data
function getImageData(image, width, height) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = width;
  canvas.height = height;
  context.drawImage(image, 0, 0, width, height);
  return context.getImageData(0, 0, width, height).data;
}

// Particle system with instancing and input interaction
const InstancedParticles = ({ texture, mouse }) => {
  const meshRef = useRef();
  const [originalPositions, setOriginalPositions] = useState(null);
  const numParticles = 500;
  const imageWidth = texture.image.width;
  const imageHeight = texture.image.height;

  useEffect(() => {
    const positions = new Float32Array(numParticles * 3);
    const instanceColors = new Float32Array(numParticles * 3);

    const imageData = getImageData(texture.image, imageWidth, imageHeight);
    let index = 0;
    for (let y = 0; y < imageHeight; y++) {
      for (let x = 0; x < imageWidth; x++) {
        const pixelIndex = (y * imageWidth + x) * 4;
        const r = imageData[pixelIndex] / 255;
        const g = imageData[pixelIndex + 1] / 255;
        const b = imageData[pixelIndex + 2] / 255;
        const a = imageData[pixelIndex + 3] / 255;

        if (a > 0.5 && index < numParticles) {
          positions[index * 3] = (x / imageWidth - 0.5) * 2; // X position
          positions[index * 3 + 1] = -(y / imageHeight - 0.5) * 2; // Y position
          positions[index * 3 + 2] = 0; // Z position

          instanceColors[index * 3] = r;
          instanceColors[index * 3 + 1] = g;
          instanceColors[index * 3 + 2] = b;

          index++;
        }
      }
    }

    setOriginalPositions(positions.slice());

    const geometry = new THREE.InstancedBufferGeometry();
    geometry.setAttribute(
      "instancePosition",
      new THREE.InstancedBufferAttribute(positions, 3)
    );
    geometry.setAttribute(
      "instanceColor",
      new THREE.InstancedBufferAttribute(instanceColors, 3)
    );
    meshRef.current.geometry = geometry; // Assign the geometry to the mesh
  }, [texture]);

  useFrame(() => {
    // Ensure meshRef.current and its attributes are defined
    if (
      meshRef.current &&
      meshRef.current.geometry &&
      meshRef.current.geometry.attributes.instancePosition &&
      originalPositions // Check if originalPositions is not null
    ) {
      const positions =
        meshRef.current.geometry.attributes.instancePosition.array;

      // Simple interaction: move particles based on mouse input
      for (let i = 0; i < positions.length; i += 3) {
        const dx = mouse.x - positions[i];
        const dy = mouse.y - positions[i + 1];
        const dist = Math.sqrt(dx * dx + dy * dy);

        if (dist < 0.3) {
          positions[i] += dx * 0.01;
          positions[i + 1] += dy * 0.01;
        } else {
          positions[i] += (originalPositions[i] - positions[i]) * 0.05;
          positions[i + 1] +=
            (originalPositions[i + 1] - positions[i + 1]) * 0.05;
        }
      }

      // Mark the attribute as needing an update
      meshRef.current.geometry.attributes.instancePosition.needsUpdate = true;
    }
  });

  return (
    <instancedMesh ref={meshRef} args={[null, null, numParticles]}>
      <sphereGeometry args={[0.05, 16, 16]} />
      <meshBasicMaterial vertexColors depthWrite={false} />
    </instancedMesh>
  );
};

const SectionFour = () => {
  const [texture, setTexture] = useState();
  const [mouse, setMouse] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load(
      academy,
      (loadedTexture) => {
        console.log("Texture loaded successfully", loadedTexture); // Log loaded texture
        setTexture(loadedTexture);
      },
      undefined,
      (error) => {
        console.error("Error loading texture:", error); // Log any errors
      }
    );
  }, []);

  const handleMouseMove = (event) => {
    const { clientX, clientY, innerWidth, innerHeight } = event;
    setMouse({
      x: (clientX / innerWidth) * 2 - 1,
      y: -(clientY / innerHeight) * 2 + 1,
    });
  };

  const BlogCard = ({ imageSrc, title, content, link }) => (
    <div
      style={{
        borderRadius: "10px",
        overflow: "hidden",

        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "220px",
          backgroundImage: `url(${imageSrc})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div style={{ padding: "16px" }}>
        <h3
          style={{
            fontSize: "18px",
            color: "#011627",
            fontWeight: 500,
            marginBottom: "8px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontSize: "14px",
            color: "#6c757d",
            marginBottom: "8px",
          }}
        >
          {content}
        </p>
        <p
          style={{
            fontSize: "12px",
            color: "#6c757d",
            marginBottom: "16px",
          }}
        >
          Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer
          Scelerisque Sem Massa, Sit Amet Elementum
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <a
            href={link}
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#28a745",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textDecoration: "none",
            }}
          >
            →
          </a>
        </div>
      </div>
    </div>
  );

  const blogData = [
    {
      imageSrc: one, // Put your image URL here
      title: "Lorem Ipsum Dolor Sit Amet",
      content:
        "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Scelerisque Sem Massa, Sit Amet Elementum",
      link: "#",
    },
    {
      imageSrc: two, // Put your image URL here
      title: "Lorem Ipsum Dolor Sit Amet",
      content:
        "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Scelerisque Sem Massa, Sit Amet Elementum",
      link: "#",
    },
    {
      imageSrc: three, // Put your image URL here
      title: "Lorem Ipsum Dolor Sit Amet",
      content:
        "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Scelerisque Sem Massa, Sit Amet Elementum",
      link: "#",
    },
  ];

  return (
    <div style={{ height: "340vh",display:"flex",flexDirection:"column", alignItems:"flex-start", width: "100%" }}>
      <div
        className="section-one"
        style={{ height: "23%", width:"100%",paddingTop:"0px",paddingBottom:"0px", alignItems:"flex-start"}}
        onMouseMove={handleMouseMove}
      >
        <div className="content">
          <div style={{ color: "#011627", fontSize: "24px" }}>
            A C A D E M Y
          </div>

          <h1 style={{ fontSize: "64px" }}>
            Unlock your potential with RIU's{" "}
          </h1>
          <h1 style={{ fontSize: "64px" }}>BRSR Certification Course. Lead </h1>
          <h1 style={{ fontSize: "64px" }}>with sustainability.</h1>
          <p>
            Join our academy and lead the way in sustainable business practices.
          </p>
          <button
            style={{ zIndex: 20, position: "relative", fontWeight: 600 }}
            className="cta-button"
          >
            Academy
          </button>
        </div>

        <div
          className="image-container"
          style={{
            height: "100%",
            marginLeft: "-8%",
            marginTop: "0%",
            width: "38%",
          }}
        >
          {texture && (
            <>
              <img
                src={academy}
                style={{ height: "100%", width: "100%", marginLeft: "-30%" }}
              />

              <Canvas>
                <InstancedParticles texture={texture} mouse={mouse} />
              </Canvas>
            </>
          )}
        </div>
      </div>
      <div style={{ height: "45%", background: "#E9ECEF", width:"100%" }}>
        <div
          style={{
            height: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "cneter",
            paddingTop: "2%",
          }}
        >
          <div style={{ color: "#011627", fontSize: "24px" }}>
            CLIENT STORIES
          </div>
          <div
            style={{ color: "#011627", fontSize: "40px", marginBottom: "2%" }}
          >
            WHAT OUR CLIENT SAYS
          </div>

          <div
            style={{
              width: "75%",
              background: "#D0DDE4",
              borderRadius: "2%",
              padding: "3%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                fontSize: "40px",
                fontWeight: 500,
              }}
            >
              Great job! Concise, clear, and impactful. The post and infographic
              effectively showcase the value of our ESG SaaS solution. Well
              done!
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                marginTop:"-4%",
                justifyContent: "flex-end",
              }}
            >
              <img src={man} />
            </div>
          </div>
        </div>
        <div style={{ height: "50%" }}>
          <section style={{ padding: "40px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ fontSize: "40px", color: "#011627" }}>
                Blogs & Publications
              </h2>
              <a
                href="#"
                style={{
                  fontSize: "14px",
                  color: "#3F88A5",
                  border: "1px solid #3F88A5",
                  borderRadius: "5px",
                  padding: "10px",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  textDecoration: "none",
                  fontWeight: 500,
                }}
              >
                VIEW ALL
              </a>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                gap: "20px",
              }}
            >
              {blogData.map((blog, index) => (
                <BlogCard
                  key={index}
                  imageSrc={blog.imageSrc}
                  title={blog.title}
                  content={blog.content}
                  link={blog.link}
                />
              ))}
            </div>
          </section>
        </div>
      </div>
      <div
        className="section-one"
        style={{ height: "23%", paddingTop: "2%", width:"100%",  }}
        onMouseMove={handleMouseMove}
      >
        <div className="content">
          <div
            style={{
              color: "#011627",
              fontSize: "24px",
              display: "flex",
              gap: "1em",
            }}
          >
            <span>G E T</span>
            <span>I N</span>
            <span>T O U C H</span>
          </div>

          <h1 style={{ fontSize: "64px" }}>Let's Create Change </h1>
          <h1 style={{ fontSize: "64px" }}>Together. Reach Out Today</h1>

          <p style={{ fontSize: "24px", fontWeight: 500, marginBottom: "2px" }}>
            Connect with us to spark sustainable action. Let's make a
          </p>
          <p style={{ fontSize: "24px", fontWeight: 500 }}>
            difference together.
          </p>
          <button
            style={{ zIndex: 20, position: "relative", fontWeight: 600 }}
            className="cta-button"
          >
            Schedule a call
          </button>
        </div>

        <div
          className="image-container"
          style={{
            height: "100%",
            marginLeft: "0%",
            marginTop: "0%",
            width: "30%",
          }}
        >
          {texture && (
            <>
              <img
                src={lets}
                style={{
                  height: "100%",
                  width: "170%",
                  marginLeft: "-125%",
                  maxWidth: "200%",
                }}
              />

              <Canvas>
                <InstancedParticles texture={texture} mouse={mouse} />
              </Canvas>
            </>
          )}
        </div>
      </div>
      <div style={{height:"18%", width:"100%"}}>
        <Footer/>
      </div>
    </div>
  );
};

export default SectionFour;
