import React from 'react';
import study from './study.png'

const SectionTwo = ({  }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    color: "#333",
    backgroundColor: "#F5F9FA", // Light background color
  };

  const titleStyle = {
    fontSize: "32px",
    fontWeight:200,
    marginBottom: "10px",
  };

  const subtitleStyle = {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "20px",
    color: "#011627",
  };

  const imageContainerStyle = {
    width: "60%", // Adjust image width as needed
    marginBottom: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
    filter: "grayscale(100%)", // Optional: grayscale effect
  };

  const descriptionStyle = {
    fontSize: "20px",
    color: "#555",
    maxWidth: "900px",
    lineHeight: "1.5",
  };

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>Empower Your Business with Responsible Practices</div>
      <div style={subtitleStyle}>Enroll In Our Comprehensive 30-Hour Course</div>
      <div style={imageContainerStyle}>
        <img src={study} alt="Course" style={imageStyle} />
      </div>
      <div style={descriptionStyle}>
        Join RIU.ai Academy to excel in Business Responsibility & Sustainability Reporting (BRSR).
        Our comprehensive certification course equips professionals at all levels with essential
        skills for navigating corporate responsibility. Stay ahead in today’s evolving business world. Enroll now.
      </div>
    </div>
  );
};

export default SectionTwo;
