import React, { useState } from 'react';
import { Form, Button, Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import hi from './globe.png'
import './Custom.css'

const SectionTwo = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeZone, setTimeZone] = useState("Eastern Time - USA & Canada");

  const availableTimes = [
    "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM",
    "11:30 AM", "12:00 PM", "12:30 PM", "01:00 PM",
  ];

  const timeZones = [
    "Eastern Time - USA & Canada", "Central Time - USA", "Pacific Time - USA",
    "Greenwich Mean Time", "Central European Time", "Australian Eastern Time"
  ];
  return (
    <div>
         <div className="container my-5">
    <h2 className="text-center mb-4" style={{fontSize:"40px"}}>Unlock the potential of ESG compliance for your business.</h2>
    <Form>
      <div className="mb-3" style={{fontSize:"28px"}}>Personal Information</div>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="firstName">
            <Form.Label style={{fontSize:"24px", fontWeight:"bold"}}>First Name</Form.Label>
            <Form.Control style={{padding:"15px", border:"2px solid black"}} type="text" placeholder="Enter first name" />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="lastName">
            <Form.Label style={{fontSize:"24px", fontWeight:"bold"}}>Last Name</Form.Label>
            <Form.Control style={{padding:"15px", border:"2px solid black"}} type="text" placeholder="Enter last name" />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="phoneNumber">
            <Form.Label style={{fontSize:"24px", fontWeight:"bold"}}>Phone Number</Form.Label>
            <Form.Control style={{padding:"15px", border:"2px solid black"}} type="text" placeholder="Enter phone number" />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="emailAddress">
            <Form.Label style={{fontSize:"24px", fontWeight:"bold"}}>Email Address</Form.Label>
            <Form.Control style={{padding:"15px", border:"2px solid black"}} type="email" placeholder="Enter email address" />
          </Form.Group>
        </Col>
      </Row>

      <div className="mt-4 mb-3" style={{fontSize:"28px"}}>Company Information</div>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group controlId="companyName">
            <Form.Label style={{fontSize:"24px", fontWeight:"bold"}}>Company Name</Form.Label>
            <Form.Control style={{padding:"15px", border:"2px solid black"}} type="text" placeholder="Enter company name" />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="jobTitle">
            <Form.Label style={{fontSize:"24px", fontWeight:"bold"}}>Job Title</Form.Label>
            <Form.Control style={{padding:"15px", border:"2px solid black"}} as="select">
              <option>Select the job title</option>
              <option>Manager</option>
              <option>Director</option>
              <option>Engineer</option>
              {/* Add more options as needed */}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  </div>

  <div className="mb-3" style={{marginLeft:"12%", fontSize:"28px"}}>
    
    
    Preferred Demo Date and Time</div>

         <div className="container my-4  border rounded" style={{ height:"70vh",maxWidth: '60%',padding:"4rem", backgroundColor: '#e9f0f3', borderColor:"1px solid #839DA6" }}>
      <Row>
        <Col md={6}>
          {/* <h5>{selectedDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</h5> */}
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            inline
            calendarClassName="custom-calendar"
            dayClassName={(date) =>
              date.getDate() === selectedDate.getDate() ? "selected-day" : undefined
            }
          />
          <Form.Group className="" style={{marginTop:"10%"}}>
            <Form.Label>

                <img src={hi} style={{marginRight:"5px"}}/>
                
                Time Zone</Form.Label>
            <Form.Control as="select" value={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
              {timeZones.map((zone, index) => (
                <option key={index} value={zone}>{zone}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6} className="d-flex flex-column">
          <div  style={{fontWeight:"bold", fontSize:"24px"}} className="text-left">{selectedDate.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long' })}</div>
          <div className="time-selection mt-4" style={{ overflowY: 'auto', maxHeight: '40vh' }}>
            {availableTimes.map((time, index) => (
             <Button
             key={index}
             variant={selectedTime === time ? "primary" : "outline-secondary"}
             className={`w-100 mb-2 ${selectedTime === time ? "selected-time-button" : "unselected-time-button"}`}
             onClick={() => setSelectedTime(time)}
           >
             {time}
           </Button>
            ))}
          </div>
        </Col>
      </Row>
    </div>
    <div className="mb-3" style={{marginLeft:"22%", fontSize:"20px"}}>
    
    
    Additional Comments and Questions</div>
    <Form.Control as="textarea" style={{width:"60%", marginLeft:"20%",marginBottom:"2%"}}/>
    <button className='selected-time-button' style={{width:"60%", marginLeft:"20%",padding:"10px 5px", borderRadius:"5px"}}>
        SUBMIT
    </button>
    </div>
   
  );
};

export default SectionTwo;
