import React, { useRef, useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { TextureLoader } from "three";
import lightBulb from "../Resources/bulb.png";
import "./sectionone.css";
import ParticleBackground from "../Resources/ParticleBackground";

// Utility function to load image and get pixel data
function getImageData(image, width, height) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = width;
  canvas.height = height;
  context.drawImage(image, 0, 0, width, height);
  return context.getImageData(0, 0, width, height).data;
}

// Particle system with instancing and input interaction



const LogoParticles = ({ imageUrl }) => {
  const mountRef = useRef(null);
  const particles = useRef([]);
  const originalPositions = useRef([]);
  const particleGeometry = useRef(null);
  const isScattered = useRef(false);

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.z = 400;

    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(width, height);
    mountRef.current.appendChild(renderer.domElement);

    // Load the logo image
    const loader = new THREE.TextureLoader();
    loader.load(imageUrl, (texture) => {
      const particleMaterial = new THREE.PointsMaterial({
        size: 2,
        map: texture,
        transparent: true,
      });

      // Use the texture's data to create particles at each pixel
      const imgCanvas = document.createElement("canvas");
      const imgContext = imgCanvas.getContext("2d");
      imgCanvas.width = texture.image.width;
      imgCanvas.height = texture.image.height;
      imgContext.drawImage(texture.image, 0, 0);
      const imageData = imgContext.getImageData(0, 0, imgCanvas.width, imgCanvas.height);

      // Initialize particles
      for (let y = 0; y < imgCanvas.height; y += 2) {
        for (let x = 0; x < imgCanvas.width; x += 2) {
          const pixelIndex = (y * imgCanvas.width + x) * 4;
          const a = imageData.data[pixelIndex + 3];
          if (a > 128) { // Only use opaque pixels
            const particle = new THREE.Vector3(
              x - imgCanvas.width / 2,
              imgCanvas.height / 2 - y,
              0
            );
            particles.current.push(particle);
            originalPositions.current.push(particle.clone());
          }
        }
      }

      particleGeometry.current = new THREE.BufferGeometry().setFromPoints(particles.current);
      const particleSystem = new THREE.Points(particleGeometry.current, particleMaterial);
      scene.add(particleSystem);

      const scatterParticles = () => {
        if (isScattered.current) return; // Only scatter if not already scattered

        particles.current.forEach((particle) => {
          particle.x += (Math.random() - 0.5) * 20;
          particle.y += (Math.random() - 0.5) * 20;
          particle.z += (Math.random() - 0.5) * 20;
        });

        particleGeometry.current.attributes.position.needsUpdate = true; // Update the geometry
        isScattered.current = true; // Set to true after scattering
      };

      const resetParticles = () => {
        if (!isScattered.current) return; // Only reset if already scattered

        particles.current.forEach((particle, i) => {
          particle.x = originalPositions.current[i].x;
          particle.y = originalPositions.current[i].y;
          particle.z = originalPositions.current[i].z;
        });

        particleGeometry.current.attributes.position.needsUpdate = true; // Update the geometry
        isScattered.current = false; // Reset state
      };

      // Add event listeners for mouse enter and leave
      const mountNode = mountRef.current;
      mountNode.addEventListener("mouseenter", scatterParticles);
      mountNode.addEventListener("mouseleave", resetParticles);

      const animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };
      animate();

      // Cleanup function
      return () => {
        mountNode.removeEventListener("mouseenter", scatterParticles);
        mountNode.removeEventListener("mouseleave", resetParticles);
        renderer.dispose();
        mountNode.removeChild(renderer.domElement);
      };
    });
  }, [imageUrl]);

  return <div ref={mountRef} style={{ width: "100%", height: "100%" }} />;
};


const SectionOne = () => {
  const [texture, setTexture] = useState();
  const [mouse, setMouse] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load(
      lightBulb,
      (loadedTexture) => {
        console.log('Texture loaded successfully', loadedTexture); // Log loaded texture
        setTexture(loadedTexture);
      },
      undefined,
      (error) => {
        console.error('Error loading texture:', error); // Log any errors
      }
    );
  }, []);

  const handleMouseMove = (event) => {
    const { clientX, clientY, innerWidth, innerHeight } = event;
    setMouse({
      x: (clientX / innerWidth) * 2 - 1,
      y: -(clientY / innerHeight) * 2 + 1,
    });
  };

  return (
    <div
      className="section-one"
      style={{ height: "86vh", paddingTop:"0px" }}
      onMouseMove={handleMouseMove}
    >
        <ParticleBackground/>
      <div className="content">
        
        <h1>Revolutionize Your Sustainability Journey with RIU</h1>
        <p>
          From Data to Action in Hours. Transform ESG performance across supply
          chains effortlessly. Explore the future of sustainability with us.
        </p>
        <button style={{zIndex:20, position:"relative"}} className="cta-button">Book A Demo</button>
      </div>

      <div className="image-container">
        {texture && (
            <>
            <img src={lightBulb}/>
            
            </>
         
        )}
      </div>
    </div>
  );
};

export default SectionOne;
