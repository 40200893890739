import React from 'react';
import back from "./back.png"

const ComponentTwo = () => {
  const containerStyle = {
    backgroundColor: "#F8F9FA",
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  };

  const overlayStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:"65%",
    zIndex: 2,
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    maxWidth: "300px", // Adjust size as needed
    opacity: 0.7,
  };

  const headerStyle = {
    fontSize: "32px",
    fontWeight: "bold",
    marginBottom: "50px",
    color: "#333",
  };

  const paragraphStyle = {
    fontSize: "24px",
    color: "#555",
    marginBottom: "20px",
  };



  return (
    <div style={containerStyle}>
      {/* Overlay content */}
      <div style={overlayStyle}>
        <h2 style={headerStyle}>Transform Your Career Today</h2>
        <p style={paragraphStyle}>
        Elevate your career by enrolling in our BRSR Professional Certification Course. Whether you're a business professional, ESG practitioner, or an advocate for positive change, this course equips you with the tools and knowledge to drive responsible and sustainable practices within your organization.
        </p>
        <button style={{zIndex:20, fontWeight:700,padding:"12px 20px", position:"relative"}} className="cta-button" >SCHEDULE A CALL</button>
      </div>

      {/* Background Image */}
      <img
        src={back}// Replace with the actual image path
        alt="Background"
        style={imageStyle}
      />
    </div>
  );
};

export default ComponentTwo;
