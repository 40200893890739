import React from "react";
import "./BlogDetail.css"; // Styling for the detailed blog view
import blogg from "./blog.png";
import place from "./place.png"

const BlogDetail = ({ blog, onBack }) => {
    const BlogCard = ({ imageSrc, title, content, link }) => (
        <div
          style={{
            borderRadius: "10px",
            overflow: "hidden",
    
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: "220px",
              backgroundImage: `url(${imageSrc})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <div style={{ padding: "16px" }}>
            <h3
              style={{
                fontSize: "18px",
                color: "#011627",
                fontWeight: 500,
                marginBottom: "8px",
              }}
            >
              {title}
            </h3>
            <p
              style={{
                fontSize: "14px",
                color: "#6c757d",
                marginBottom: "8px",
              }}
            >
              {content}
            </p>
            <p
              style={{
                fontSize: "12px",
                color: "#6c757d",
                marginBottom: "16px",
              }}
            >
              Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer
              Scelerisque Sem Massa, Sit Amet Elementum
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <a
                href={link}
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: "#28a745",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                →
              </a>
            </div>
          </div>
        </div>
      );
    const blogData = [
        {
          imageSrc: place, // Put your image URL here
          title: "Lorem Ipsum Dolor Sit Amet",
          content:
            "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Scelerisque Sem Massa, Sit Amet Elementum",
          link: "#",
        },
        {
          imageSrc: place, // Put your image URL here
          title: "Lorem Ipsum Dolor Sit Amet",
          content:
            "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Scelerisque Sem Massa, Sit Amet Elementum",
          link: "#",
        },
        {
          imageSrc: place, // Put your image URL here
          title: "Lorem Ipsum Dolor Sit Amet",
          content:
            "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Scelerisque Sem Massa, Sit Amet Elementum",
          link: "#",
        },
      ];
  return (
    <div className="blog-detail">
      <button onClick={onBack} className="back-button">
        Back
      </button>

      <div style={{ width: "100%", display: "flex", marginBottom: "5vh" }}>
        <div className="blog-header" style={{ width: "60%" }}>
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <div
              className="blog-category"
              style={{
                border: "1px solid black",
                padding: "7px 17px",
                letterSpacing: "3px",
                fontWeight: 400,
                marginRight: "10px",
              }}
            >
              {blog.category}
            </div>
            <div
              className="blog-author"
              style={{
                border: "none",
                padding: "7px 17px",
                letterSpacing: "1px",
                fontWeight: 400,
                marginRight: "10px",
              }}
            >
              5 min READ
            </div>
          </div>

          <h1 className="blog-title">{blog.title}</h1>
          <p className="blog-subtitle">{blog.subtitle}</p>
        </div>
        <div>
          <img src={blogg} />
        </div>
      </div>

      <hr style={{ marginBottom: "7vh" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {blog.sections.map((section, index) => (
          <div key={index} className="blog-section">
            <h2 className="section-heading">{section.heading}</h2>
            <p className="section-text">{section.text}</p>
          </div>
        ))}
      </div>
      <div style={{ height: "50%" }}>
          <section style={{ padding: "40px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ fontSize: "40px", color: "#011627" }}>
                Blogs & Publications
              </h2>
              <a
                href="#"
                style={{
                  fontSize: "14px",
                  color: "#3F88A5",
                  border: "1px solid #3F88A5",
                  borderRadius: "5px",
                  padding: "10px",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  textDecoration: "none",
                  fontWeight: 500,
                }}
              >
                VIEW ALL
              </a>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                gap: "20px",
              }}
            >
              {blogData.map((blog, index) => (
                <BlogCard
                  key={index}
                  imageSrc={blog.imageSrc}
                  title={blog.title}
                  content={blog.content}
                  link={blog.link}
                />
              ))}
            </div>
          </section>
        </div>
    </div>
  );
};

export default BlogDetail;
