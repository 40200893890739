import React from 'react'
import CourseFeatures from './ComponentOne'
import ComponentTwo from './ComponentTwo'
import ComponentThree from './ComponentThree'

const SectionThree = () => {
  return (
    <div>
        <CourseFeatures/>
        <ComponentTwo/>
        <ComponentThree/>
    </div>
  )
}

export default SectionThree