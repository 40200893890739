import React, { useRef, useEffect, useState } from "react";
import { TextureLoader } from "three";
import tree from "./academy.png";
import ParticleBackground from "../../../Home/Components/Resources/ParticleBackground";

// Particle system with instancing and input interaction

const SectionOne = () => {
  const [texture, setTexture] = useState();
  const [mouse, setMouse] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load(
      tree,
      (loadedTexture) => {
        console.log("Texture loaded successfully", loadedTexture); // Log loaded texture
        setTexture(loadedTexture);
      },
      undefined,
      (error) => {
        console.error("Error loading texture:", error); // Log any errors
      }
    );
  }, []);

  const handleMouseMove = (event) => {
    const { clientX, clientY, innerWidth, innerHeight } = event;
    setMouse({
      x: (clientX / innerWidth) * 2 - 1,
      y: -(clientY / innerHeight) * 2 + 1,
    });
  };

  return (
    <div
      className="section-one"
      onMouseMove={handleMouseMove}
      style={{
        position: "relative",
        height: "110vh",
        paddingTop: "0px",
        marginTop: "0px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        textAlign: "center",
        color: "#1a1a1a",
        backgroundImage: `url(${tree})`, 
        backgroundSize: "100% auto", // 70% of width, auto for height
        backgroundPosition: "center 80%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <ParticleBackground />

      <div
        className="content"
        style={{
          position: "relative",
          width: "100%",
    
          marginTop: "-5%",
          zIndex: 10,
          color: "#1a1a1a",
        }}
      >
        <h2
          style={{
            fontFamily: "'Open Sans', sans-serif", // Specify Open Sans font
            fontSize: "24px", // Set font size to 24px
            color: "#6c757d",
            margin: 0,
            letterSpacing: "0.1em",
          }}
        >
          SOLUTIONS
        </h2>

        <h1
          style={{
            fontFamily: "'Open Sans', sans-serif", // Specify Open Sans font
            fontSize: "64px", // Set font size to 24px
            color: "#011627",
            fontWeight: "bold",
          }}
        >
          Driving Global Impact with Tailored 
        </h1>
        <h1
          style={{
            fontFamily: "'Open Sans', sans-serif", // Specify Open Sans font
            fontSize: "64px", // Set font size to 24px
            color: "#011627",
            fontWeight: "bold",
          }}
        >
        Sustainable Solutions
        </h1>
       
        <div style={{fontSize:"24px", fontWeight:700,color:"#011627", marginBottom:"3%"}}>
        Revolutionize your ESG performance with our innovative tools. Book a demo today and embark on a journey towards a greener future
        </div>

   

        <span
          className="scroll-text"
          style={{
            marginTop: "100px",
            fontSize: "24px",
            fontWeight: 600,
            color: "#1a1a1a",
          }}
        >
          REVIEW OUR SOLUTIONS
        </span>

        <div
          className="scroll-indicator"
          style={{
            width: "30px",
            height: "50px",
            border: "2px solid #1a1a1a",
            borderRadius: "20px",
            margin: "20px auto 0",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              backgroundColor: "#1a1a1a",
              animation: "scroll 1.5s infinite",
            }}
          />
        </div>
      </div>

      <style>
        {`
      @keyframes scroll {
        0% { opacity: 1; top: 8px; }
        50% { opacity: 0; top: 20px; }
        100% { opacity: 1; top: 8px; }
      }
    `}
      </style>
    </div>
  );
};

export default SectionOne;
