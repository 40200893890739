import React from 'react'
import SectionThree from '../../../ESGDataManagement/Components/SectionThree/SEctionThree'

const SectionThreee = () => {
  return (
    <div>
        <SectionThree/>
    </div>
  )
}

export default SectionThreee