import React from 'react'
import ScrollSpyComponent from './Components/ScrollSpyComponentAcademy'

const Academy = () => {
  return (
    <div style={{height:"86vh"}}> 
        <ScrollSpyComponent/>
    </div>
  )
}

export default Academy