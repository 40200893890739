import React from 'react';
 import TabbedNavigation from "./Component/TabbedNavigation";
 import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="">
    <TabbedNavigation/>
    </div>
  );
}

export default App;
