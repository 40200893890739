import React from 'react';
import esg from "./esgconsultant.png"
import join from "./join.png"
import green from "./greenarrow.png"
import blue from "./bluearrow.png"
import tree from "./tree.png"



const SustainabilityCommitment = () => {
  const commitmentData = [
    {
      title: 'Find An ESG Consultant',
      description: 'Explore A Variety Of Consultant Profiles To Guide Your ESG Policy-Making And Registration Process.',
      image: esg, // Replace with your actual image path
    },
    {
      title: 'Join Our Sustainable Journey',
      description: 'Discover How We Are Reducing Our Carbon Footprint Through Innovative Projects And Track Your Carbon Credits.',
      image: join, // Replace with your actual image path
    },
    {
      title: 'RIU Tree Plantations',
      description: 'Participate In Our Tree Plantation Drives And Contribute To A Greener Planet.',
      image: tree, // Replace with your actual image path
    },
  ];

  return (
    <section style={{ backgroundColor: '#e8f5f2', padding: '50px 0', height:"25%" }}>
      <h2 style={{ paddingLeft:"3%",textAlign: 'left', color: '#4a595b', fontSize: '24px', fontWeight: 500, marginBottom: '40px' }}>
        OUR COMMITMENT TO SUSTAINABILITY
      </h2>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '30px',
          padding: '0 50px',
        }}
      >
        {commitmentData.map((item, index) => (
          <div key={index} style={{ textAlign: 'left', width: '33%' }}>
            <div style={{ borderRadius: '15px', overflow: 'hidden', marginBottom: '20px' }}>
              <img
                src={item.image}
                alt={item.title}
                style={{ width: '100%', height: 'auto', borderRadius: '15px' }}
              />
            </div>
            <h3 style={{ fontSize: '20px', color: '#011627', fontWeight: 'bold' }}>{item.title}</h3>
            <p style={{ color: '#011627', fontSize: '16px', lineHeight: 1.6, margin: '10px 0 20px' }}>
              {item.description}
            </p>
            <div style={{cursor: 'pointer' }}>
                <img src={index%2===0? green: blue}/>


            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SustainabilityCommitment;
