import React from 'react'
import ScrollSpyComponent from './Components/ScrollSpyComponent'

const DemoBook = () => {
  return (
    <div>
        <ScrollSpyComponent/>
    </div>
  )
}

export default DemoBook