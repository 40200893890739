import React from "react";

const teamData = {
  coFounders: [
    { name: "Name Of The Employee", position: "Co-Founder" },
    { name: "Name Of The Employee", position: "Co-Founder" },
    { name: "Name Of The Employee", position: "Co-Founder" },
  ],
  coreTeam: [
    { name: "Sharique Zia", position: "Co-Founder" },
    { name: "Name Of The Employee", position: "Position" },
    { name: "Name Of The Employee", position: "Position" },
  ],
  advisoryBoard: [
    { name: "Name Of The Employee", position: "Position" },
    { name: "Name Of The Employee", position: "Position" },
    { name: "Name Of The Employee", position: "Position" },
  ],
  associates: [
    { name: "Name Of The Employee", position: "Position" },
    { name: "Name Of The Employee", position: "Position" },
    { name: "Name Of The Employee", position: "Position" },
  ],
};

const SectionThree = () => {
  return (
    <div
      style={{
        padding: "20px",
        height: "310vh",
        paddingLeft: "0px",
        paddingRight: "0px",
        backgroundColor: "#D9E7ED",
        width: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "transparent",
          padding: "2rem",
          height: "10%",

          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "20%",
            height: "100%",
            display: "flex",
            paddingTop: "0.1rem",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <h2
            style={{
              fontSize: "38px",
              color: "#011627",
              fontWeight: 700,

              flexShrink: 0,
            }}
          >
            OUR TEAM
          </h2>
        </div>
        <div
          style={{
            width: "75%",
            display: "flex",
            flexWrap: "wrap",
            height: "100%",
            paddingTop: "0.1rem",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <p style={{ color: "#011627", fontSize: "18px" }}>
            At our core, we're a passionate team of technologists and ESG Our
            Core Team is a Diverse Assembly of Seasoned Professionals United by
            a Passion for Sustainability. Each Member Brings a Unique Set of
            Skills, Perspectives, and Experiences, Ranging from Data Science, AI
            Development, to ESG Analysis, Offering Holistic Solutions for Our
            Clients' Challenges. Beyond their Professional Talents, They Share
            an Unwavering Dedication to the Core Values of Integrity,
            Transparency, and Ethical Conduct. These Aren't Just Qualities We
            Talk About; They Are the Core Pillars of Trust That Define Our
            Commitment to Driving a Better, More Sustainable World.
          </p>
        </div>
      </div>

      <div style={{ width: "95%" }}>
        {Object.keys(teamData).map((section, index) => (
          <div key={index} style={{ width: "100%" , height:"61vh"}}>
            <h3
              style={{
                fontSize: "38px",
                color: "#1a1a1a",paddingLeft:"2.4rem",
              
                marginTop:"3rem",
                fontWeight:700
              }}
            >
              {section.replace(/([A-Z])/g, " $1").toUpperCase()}
            </h3>
            <div
              style={{
                display: "flex",
                width:"100%",
                height:"100%",
                padding:"0rem 2rem",
             
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {teamData[section].map((member, idx) => (
                <div style={{width:"33%", height:"100%",padding:"2rem", display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                  <div
                    key={idx}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#95a7b7",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                      borderRadius: "10px",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  ></div>
                  <div style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    {member.name}
                  </div>
                  <div
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      marginTop: "5px",
                      fontSize: "1rem",
                      color: "#d1d1d1",
                    }}
                  >
                    {member.position}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionThree;
